import React, {useEffect, useState} from "react";
import Api from "../../../core/Api";
import {useDispatch} from "react-redux";
import {replace} from "react-router-redux";
import {Alert, CircularProgress, Grid} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";

const Show = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const paramsSuffix = params['*'] !== '' ? '/' + params['*'] : '';
    const deviceId = params.id;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        Api.fetch({
            endpoint: `devices/${deviceId}`,
            method: 'GET',
        }).then((res) => {
            const d = res.response;
            dispatch(replace(`/customers/show/${d.customerId}/addresses/all/devices/${d.id}` + paramsSuffix))
        }, (err) => {
            console.log(err)
            setError(err.message || "Unkonwn error");
        }).then(() => setLoading(false));
    }, [deviceId, paramsSuffix, dispatch, location.pathname]);

    return (
        <Grid container justifyContent='center' alignItems='center' sx={{
            height: '100%'
        }}>
            <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                {loading && <CircularProgress color='primary'/>}
                {error && <Alert severity="error">{error}</Alert>}
            </Grid>
        </Grid>
    );
}

export default Show;
