import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {cloneDeep} from 'lodash';
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import {
    Add,
    Cancel,
    Check,
    ChevronLeft,
    Close,
    Delete,
    Edit,
    MoreVert,
    Replay,
    Save,
    TextFields
} from "@mui/icons-material";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../../core/Api";
import moment from "moment";
import Address from "../Utils/Address";
import {v1 as uuid} from "uuid";
import {LoadingButton} from "@mui/lab";
import Schema from "validate";
import DeleteDialog from "../../Utils/DeleteDialog";
import * as EmailValidator from "email-validator";
import TagSelect from "../../Utils/TagSelect";
import {fetchTagList} from "../../../actions/tagActions";
import {fetchPropertyList} from "../../../actions/propertyActions";
import {useDispatch, useSelector} from "react-redux";
import CheckPermissions from "../../Utils/CheckPermissions";
import FileBrowser from "../../Utils/FileBrowser";
import {Route, Routes, useMatch} from "react-router";
import PropertyInput from "../../Utils/PropertyInput";
import {formatAddress} from "../../../helper/formatAddress";
import {push} from "react-router-redux";
import ShowAddress from "./ShowAddress";
import BackButton from "../../Utils/BackButton";

const email = (val) => val ? EmailValidator.validate(val) : true;
const getCustomerDataSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('customers.validation.name'),
        },
        number: {
            required: true,
            type: String,
            message: t('customers.validation.number'),
        },
        email: {
            required: false,
            type: String,
            use: {email},
            message: t('customers.validation.email'),
        },
        telephoneNumber: {
            required: false,
            type: String,
            message: t('customers.validation.telephoneNumber'),
        },
    })
}

const getAddressSchema = (t) => {
    return new Schema({
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true,
            length: {min: 1},
            message: t('customers.validation.addressName'),
        },
        address: {
            type: String,
            required: true,
            length: {min: 1},
            message: t('customers.validation.address'),
        },
        zip: {
            type: String,
            required: true,
            message: t('customers.validation.zip'),
        },
        city: {
            type: String,
            required: true,
            message: t('customers.validation.city'),
        },
        contacts: {
            type: Array,
            each: {
                id: {
                    type: String,
                    required: true
                },
                name: {
                    type: String,
                    required: true,
                    length: {min: 1},
                    message: t('customers.validation.name'),
                },
            }
        },
        locations: {
            type: Array,
            required: true,
            length: {min: 1},
            each: {
                id: {
                    type: String,
                    required: true
                },
                name: {
                    type: String,
                    required: true,
                    length: {min: 1},
                    message: t('customers.validation.locationName'),
                },
            }
        }
    })
}


const Show = () => {
    const params = useParams();
    const customerId = params.id;

    let selectionId = null;
    let addressId = null;
    let tabId = null;
    const match1 = useMatch('/customers/show/:id/:selectionId/:addressId/:tabId/*');
    if (match1) {
        selectionId = match1.params.selectionId;
        addressId = match1.params.addressId;
        tabId = match1.params.tabId;
    }
    const match2 = useMatch('/customers/show/:id/:selectionId/:addressId');
    if (!match1 && match2) {
        selectionId = match2.params.selectionId;
        addressId = match2.params.addressId;
    }
    const match3 = useMatch('/customers/show/:id/:selectionId');
    if (!match1 && !match2 && match3) {
        selectionId = match3.params.selectionId;
    }

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const theme = useTheme()
    const [customer, setCustomer] = React.useState({});

    const [menu, setMenu] = React.useState(null);

    const externalLoading = useSelector((state) => state.tags.isFetching || state.properties.isFetching)
    const [customersLoading, setLoading] = React.useState(false);
    const loading = externalLoading || customersLoading;
    const [saving, setSaving] = React.useState(false);

    const [addressMenu, setAddressMenu] = React.useState(null);

    const [editCustomerData, setEditCustomerData] = React.useState(null);
    const [errors, setErrors] = React.useState({});
    const [editCustomerAddress, setEditCustomerAddress] = React.useState(null);

    const [addCustomerAddress, setAddCustomerAddress] = React.useState(null);
    const [addressErrors, setAddressErrors] = React.useState({});

    const [editCustomerProperties, setEditCustomerProperties] = React.useState(null);

    const [deleteCustomerAddress, setDeleteCustomerAddress] = React.useState(null);

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const tagList = useSelector((state) => state.tags.list)
    const propertyList = useSelector((state) => state.properties.list)

    const handleChange = (key, value) => {
        setEditCustomerData({
            ...editCustomerData,
            [key]: value
        })
    }

    const handlePropertyChange = (id, value) => {
        let internalValue = value ? value.toString() : value;
        if (value === '') {
            internalValue = null;
        }

        const properties = editCustomerProperties
        const index = properties.findIndex((property) => property.id === id)

        if (index === -1) {
            properties.push({
                id: id,
                value: internalValue
            })
        } else {
            properties[index]['value'] = internalValue
        }
        setEditCustomerProperties(properties.slice())
    }

    const openAddAddressDialog = () => {
        setAddCustomerAddress({
            id: uuid(),
            name: 'Adresse ' + (customer.addresses.length + 1),
            main: false,
            address: '',
            zip: '',
            city: '',
            additional: '',
            contacts: [],
            locations: [
                {
                    id: uuid(),
                    name: '',
                }
            ],
        })
    }

    const editData = () => {
        setErrors({});
        setSaving(true);

        const v = getCustomerDataSchema(t)
        const errors = v.validate(cloneDeep(editCustomerData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrors(errorObject)
            return;
        }

        const saveCustomer = cloneDeep(customer);
        saveCustomer.number = editCustomerData.number;
        saveCustomer.email = editCustomerData.email;
        saveCustomer.name = editCustomerData.name;
        saveCustomer.telephoneNumber = editCustomerData.telephoneNumber;
        saveCustomer.tags = editCustomerData.tags;

        Api.fetch({
            endpoint: 'customers/' + saveCustomer.id,
            method: "PUT",
            body: saveCustomer
        })
            .then(response => {
                setCustomer(response.response);
                setEditCustomerData(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const onDeleteClick = () => {
        setSaving(true);
        const saveCustomer = cloneDeep(customer);
        saveCustomer.addresses = saveCustomer.addresses.filter((address) => {
            return address.id !== deleteCustomerAddress;
        })

        Api.fetch({
            endpoint: 'customers/' + saveCustomer.id,
            method: "PUT",
            body: saveCustomer
        })
            .then(response => {
                setCustomer(response.response);
                setDeleteCustomerAddress(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const onCustomerDeleteClick = () => {
        setIsDeleting(true);

        Api.fetch({
            endpoint: 'customers/' + customer.id,
            method: "DELETE"
        }).then(() => {
            dispatch(push('/customers'));
        }, () => {
        }).then(() => setIsDeleting(false))
    }

    const saveProperties = () => {
        setSaving(true);

        const saveCustomer = cloneDeep(customer);
        saveCustomer.properties = editCustomerProperties.slice();

        Api.fetch({
            endpoint: 'customers/' + saveCustomer.id,
            method: "PUT",
            body: saveCustomer
        })
            .then(response => {
                setCustomer(response.response);
                setEditCustomerProperties(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const editAddress = () => {
        setAddressErrors({});
        setSaving(true);

        const v = getAddressSchema(t)
        const errors = v.validate(cloneDeep(editCustomerAddress))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setAddressErrors(errorObject)
            return;
        }

        const saveCustomer = cloneDeep(customer);
        if (editCustomerAddress.main) {
            saveCustomer.addresses = saveCustomer.addresses.map((address) => {
                if (address.id !== editCustomerAddress.id) {
                    address.main = false;
                }
                return address;
            })
        }
        const i = saveCustomer.addresses.findIndex((a) => a.id === editCustomerAddress.id)
        const wasMain = saveCustomer.addresses[i].main;
        const hasMain = saveCustomer.addresses.find((a) => a.main && a.id !== editCustomerAddress.id)

        if (wasMain && !hasMain) {
            editCustomerAddress.main = true;
        }
        saveCustomer.addresses[i] = editCustomerAddress;

        Api.fetch({
            endpoint: 'customers/' + saveCustomer.id,
            method: "PUT",
            body: saveCustomer
        })
            .then(response => {
                setCustomer(response.response);
                setEditCustomerAddress(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const saveNewAddress = () => {
        setAddressErrors({});
        setSaving(true);

        const v = getAddressSchema(t)
        const errors = v.validate(cloneDeep(addCustomerAddress))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setAddressErrors(errorObject)
            return;
        }

        const saveCustomer = cloneDeep(customer);
        if (addCustomerAddress.main) {
            saveCustomer.addresses = saveCustomer.addresses.map((address) => {
                address.main = false;
                return address;
            })
        }
        saveCustomer.addresses.push(addCustomerAddress);

        Api.fetch({
            endpoint: 'customers/' + saveCustomer.id,
            method: "PUT",
            body: saveCustomer
        })
            .then(response => {
                setCustomer(response.response);
                setAddCustomerAddress(null);
            }, () => {
            }).then(() => setSaving(false))
    }


    const fetchCustomer = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: `customers/${customerId}`,
        }).then((res) => {
            setCustomer(res.response)
        }, () => {
        }).then(() => setLoading(false));
    }, [customerId])
    const fetchData = useCallback(() => {
        fetchCustomer();
        dispatch(fetchTagList())
        dispatch(fetchPropertyList())
    }, [fetchCustomer, dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData])


    const properties = propertyList.filter(property => property.areas.includes('customer')).map((p) => {
        let value = '';
        const property = customer?.properties?.find(c => c.id === p.id);
        if (property) {
            value = property.value || '';
            if (p.type === 'date' && value.length) {
                value = moment(value).format('DD.MM.YYYY HH:mm')
            } else if (p.type === 'boolean') {
                value = value?.toString() === '1' ? <Check/> : <Cancel/>
            }
        }
        return <TableRow key={p.id}>
            <TableCell>{p.name}</TableCell>
            <TableCell>{p.description}</TableCell>
            <TableCell>{value}</TableCell>
        </TableRow>
    })

    return <React.Fragment>
        <Box sx={{height: '100%',  overflowX: 'auto'}}>
        <Toolbar disableGutters={true} variant='dense'>
            <BackButton defaultTo={'/customers'} exclude={[`/customers/show/${customerId}`]} />
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
            <Grid container spacing={2} sx={{height: 'calc(100% - 32px)'}}>
            <Grid item xs={12} sm={12} md={3} sx={{pb: 2}}>
                    <Paper>
                        <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                            <Typography variant='h5'
                                        component='h1'>{customer.number}: {customer.name}</Typography>
                            <Box flexGrow={1}/>
                            <CheckPermissions list={['customers.write', 'customers.delete']}>
                                <IconButton sx={{ml: 1}} disabled={loading}
                                            onClick={(e) => setMenu(e.currentTarget)}><MoreVert/></IconButton>
                            </CheckPermissions>
                        </Toolbar>
                        <Grid container spacing={2} sx={{pl: 2, pr: 2, pb: 2}}>
                            <Grid item md={12} lg={6}>
                                <List disablePadding={true}>
                                    <ListItem disableGutters={true}>
                                        <ListItemText primary={t('customers.fields.email')}
                                                      secondary={customer.email || '-'}/>
                                    </ListItem>
                                    <ListItem disableGutters={true}>
                                        <ListItemText primary={t('customers.fields.telephone')}
                                                      secondary={customer.telephoneNumber || '-'}/>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item md={12} lg={6}>
                                <List disablePadding={true}>
                                    <ListItem disableGutters={true}>
                                        <ListItemText primary={t('customers.fields.createdAt')}
                                                      secondary={moment(customer.createdAt).format('DD.MM.YYYY HH:mm')}/>
                                    </ListItem>
                                    <ListItem disableGutters={true}>
                                        <ListItemText primary={t('customers.fields.updatedAt')}
                                                      secondary={moment(customer.updatedAt).format('DD.MM.YYYY HH:mm')}/>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <List disablePadding={true}>
                                    <ListItem disableGutters={true}>
                                        <ListItemText primary={t('customers.fields.tags')}
                                                      secondaryTypographyProps={{
                                                          component: 'div'
                                                      }}
                                                      secondary={customer.tags?.filter((tag) => tagList.find((t) => t.id === tag))?.length ? customer.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                                                          const tagDefinition = tagList.find((t) => t.id === tag);
                                                          return <Chip key={tag} label={tagDefinition.name} sx={{
                                                              mr: 1,
                                                              backgroundColor: tagDefinition.color,
                                                              color: 'white'
                                                          }}/>
                                                      }) : '-'}/>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Menu
                        anchorEl={menu}
                        open={Boolean(menu)}
                        onClose={() => setMenu(null)}
                    >
                        <MenuItem onClick={() => {
                            setMenu(null);
                            setEditCustomerData({
                                number: customer.number,
                                name: customer.name,
                                email: customer.email,
                                telephoneNumber: customer.telephoneNumber,
                                tags: customer.tags,
                            });
                        }}>
                            <ListItemIcon>
                                <Edit fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>{t('customers.editCustomer')}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setMenu(null);
                            setDeleteOpen(true);
                        }}>
                            <ListItemIcon>
                                <Delete fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>{t('customers.delete.label')}</ListItemText>
                        </MenuItem>
                    </Menu>
                    <Paper sx={{mt: 2}}>
                        <List disablePadding={true}>
                            <ListItem button component={RouterLink} selected={selectionId === 'properties'}
                                      to={'/customers/show/' + customerId + '/properties'}>
                                <ListItemText primary={t('customers.properties')}/>
                            </ListItem>
                            <ListItem button component={RouterLink} selected={selectionId === 'files'}
                                      to={'/customers/show/' + customerId + '/files'}>
                                <ListItemText primary={t('customers.files')}/>
                            </ListItem>
                        </List>
                    </Paper>
                    <Paper sx={{mt: 2}}>
                        <Toolbar variant='dense' disableGutters={true} sx={{mr: 1, ml: 1}}>
                            <Typography variant='h6'>{t('customers.addresses')}</Typography>
                            <Box flexGrow={1}/>
                            <CheckPermissions list={['customers.write']}>
                                <Button disabled={loading} startIcon={<Add/>}
                                        onClick={openAddAddressDialog}>{t('customers.addAddress')}</Button>
                            </CheckPermissions>
                        </Toolbar>
                        <List disablePadding={true}>
                            <ListItem selected={addressId === 'all'} button component={RouterLink}
                                      to={'/customers/show/' + customerId + '/addresses/all/' + (tabId || 'tickets')}>
                                <ListItemText primary={t('customers.allAddresses')}
                                              secondary={t('customers.allAddressesDescription')}/>
                            </ListItem>
                            {customer.addresses?.map((address) => {
                                return <ListItem selected={addressId === address.id} button
                                                 key={address.id} component={RouterLink}
                                                 to={'/customers/show/' + customerId + '/addresses/' + address.id + '/' + (tabId || 'tickets')}
                                                 secondaryAction={
                                                     <CheckPermissions list={['customers.write']}>
                                                         <IconButton edge="end"
                                                                     onClick={(e) => {
                                                                         e.preventDefault();
                                                                         setAddressMenu({
                                                                             anchor: e.currentTarget,
                                                                             id: address.id
                                                                         })
                                                                     }}
                                                         >
                                                             <MoreVert/>
                                                         </IconButton>
                                                     </CheckPermissions>
                                                 }
                                >
                                    <ListItemText primary={<React.Fragment>{address.name}{address.main ?
                                        <Chip size='small' sx={{ml: 2}}
                                              label={t('customers.address.main')}/> : null}</React.Fragment>}
                                                  secondary={formatAddress(address)}/>
                                </ListItem>
                            })}
                        </List>
                    </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9} sx={{height: {md: '100%', xs: 'unset', sm: 'unset'}}}>
                <Routes>
                    <Route path='addresses/:addressId/:tab/:subId/*' element={<ShowAddress/>}/>
                    <Route exact path='addresses/:addressId/:tab' element={<ShowAddress/>}/>
                    <Route exact path='addresses/:addressId' element={<Navigate
                        replace
                        to={`/customers/show/${customerId}/addresses/${addressId}/tickets`}
                    />}/>
                    <Route exact path='addresses' render={(p) => <Navigate
                        replace
                        to={`/customers/show/${customerId}/addresses/all`}
                    />}/>
                    <Route exact path='/files'
                           element={<Paper>
                               <FileBrowser type={'customer-' + customerId}/>
                           </Paper>}/>
                    <Route exact path='properties' element={<Paper>
                        <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                            <Typography variant='h5'
                                        component='h1'>{t('customers.properties')}</Typography>
                            <Box flexGrow={1}/>
                            <CheckPermissions list={['customers.write']}>
                                <Button disabled={loading || properties.length <= 0} startIcon={<Edit/>}
                                        onClick={() => setEditCustomerProperties(cloneDeep(customer.properties || []))}>{t('customers.editProperties')}</Button>
                            </CheckPermissions>
                        </Toolbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: 'bold'}}>
                                            {t('customers.fields.property')}
                                        </TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>
                                            {t('customers.fields.description')}
                                        </TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>
                                            {t('customers.fields.value')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {properties}
                                </TableBody>
                            </Table>
                            {properties.length <= 0 &&
                                <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                                    <TextFields sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                                        <Typography
                                            variant='body2'>{t('settings.properties.noPropertiesCreated')}</Typography>
                                        <Link component={RouterLink} variant='body2'
                                              to='/settings/general/properties'>{t('settings.properties.editProperties')}</Link>
                                    </Box>
                                </Box>}
                        </TableContainer>
                    </Paper>}/>
                    <Route exact path={'*'} element={<Navigate
                        replace
                        to={`/customers/show/${customerId}/addresses/all`}/>}/>
                </Routes>
            </Grid>
        </Grid>
        </Box>
        <Menu onClose={() => setAddressMenu(null)} open={Boolean(addressMenu)} anchorEl={addressMenu?.anchor}>
            <MenuItem onClick={() => {
                setEditCustomerAddress(cloneDeep(customer.addresses.find(address => address.id === addressMenu.id)));
                setAddressMenu(null);
            }}>
                <ListItemIcon>
                    <Edit fontSize="small"/>
                </ListItemIcon>
                <ListItemText> {t('customers.editAddress')}</ListItemText>
            </MenuItem>
            <MenuItem disabled={customer.addresses?.length <= 1} onClick={() => {
                setDeleteCustomerAddress(addressMenu.id);
                setAddressMenu(null);
            }}>
                <ListItemIcon>
                    <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('customers.deleteAddress')}</ListItemText>
            </MenuItem>
        </Menu>
        <Dialog maxWidth='md' fullWidth open={Boolean(editCustomerData)}>
            <DialogTitle>
                {t('customers.editContact')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditCustomerData(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editCustomerData && <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('name')}
                    helperText={errors.name}
                    margin='dense'
                    label={t('customers.fields.name')}
                    placeholder={t('customers.fields.name')}
                    value={editCustomerData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                />
                <TextField
                    fullWidth
                    margin='dense'
                    required
                    error={errors.hasOwnProperty('number')}
                    helperText={errors.number}
                    label={t('customers.fields.number')}
                    placeholder={t('customers.fields.number')}
                    value={editCustomerData.number}
                    onChange={(e) => handleChange('number', e.target.value)}
                />
                <TextField
                    fullWidth
                    margin='dense'
                    label={t('customers.fields.email')}
                    placeholder={t('customers.fields.email')}
                    value={editCustomerData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                />
                <TextField
                    fullWidth
                    margin='dense'
                    label={t('customers.fields.telephone')}
                    placeholder={t('customers.fields.telephone')}
                    value={editCustomerData.telephoneNumber}
                    onChange={(e) => handleChange('telephoneNumber', e.target.value)}
                />
                <TagSelect
                    multiple
                    margin='dense'
                    sx={{mt: 1}}
                    tags={tagList}
                    label={t('customers.fields.tags')} value={editCustomerData.tags}
                    onChange={(v) => handleChange('tags', v || null)}/>
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditCustomerData(null) && setErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={editData}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='md' fullWidth open={Boolean(editCustomerProperties)}>
            <DialogTitle>
                {t('customers.editProperties')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditCustomerProperties(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('customers.fields.property')}
                                </TableCell>
                                <TableCell>
                                    {t('customers.fields.description')}
                                </TableCell>
                                <TableCell>
                                    {t('customers.fields.value')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Boolean(editCustomerProperties) && propertyList.filter((property) => property.areas.includes('customer')).map((property) => {
                                const value = editCustomerProperties.find((propertyValue) => propertyValue.id === property.id)?.value
                                return <TableRow key={property.id}>
                                    <TableCell>{property.name}</TableCell>
                                    <TableCell>{property.description}</TableCell>
                                    <TableCell>
                                        <PropertyInput property={property} value={value}
                                                       onChange={handlePropertyChange}/>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditCustomerProperties(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={saveProperties}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('customers.saveProperties')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='md' fullWidth open={Boolean(addCustomerAddress)}>
            <DialogTitle>
                {t('customers.addAddress')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setAddCustomerAddress(null) && setAddressErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {addCustomerAddress && <Address address={addCustomerAddress}
                                                errors={addressErrors}
                                                standalone
                                                onChange={(updatedAddress) => setAddCustomerAddress(cloneDeep(updatedAddress))}/>}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setAddCustomerAddress(null) && setAddressErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={saveNewAddress}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('customers.saveAddress')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='md' fullWidth open={Boolean(editCustomerAddress)}>
            <DialogTitle>
                {t('customers.editAddress')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditCustomerAddress(null) && setAddressErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {editCustomerAddress && <Address address={editCustomerAddress}
                                                 errors={addressErrors}
                                                 standalone
                                                 onChange={(updatedAddress) => setEditCustomerAddress(cloneDeep(updatedAddress))}/>}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditCustomerAddress(null) && setAddressErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={editAddress}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('customers.editAddress')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('customers.deleteAddress')}
                      isDeleting={saving}
                      handleClose={() => setDeleteCustomerAddress(null)}
                      description={t('customers.deleteCustomerAddressDescription', {name: customer.addresses?.find(a => a.id === deleteCustomerAddress)?.name || ''})}
                      open={Boolean(deleteCustomerAddress)}/>
        <DeleteDialog onDelete={() => onCustomerDeleteClick()} title={t('customers.delete.label')}
                      isDeleting={isDeleting}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('customers.delete.description', {name: customer.name || ''})}
                      open={deleteOpen}/>
    </React.Fragment>;
};

export default Show;
