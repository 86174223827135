import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Button, Link, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BackgroundImage from '../../images/login-background.jpg'
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Lock, LoginOutlined, OpenInNew } from "@mui/icons-material";
import Api from "../../core/Api";
import Schema from 'validate'
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { push } from "@lagunovsky/redux-react-router";
import { clearUser } from "../../actions/userActions";
import * as EmailValidator from 'email-validator';
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import MaintenanceScreen from "../Utils/MaintenanceScreen";
import {clearSystemData} from "../../actions/systemDataActions";
import {clearUserNotifications} from "../../actions/userNotificationsActions";
import AwsRumSingleton from "../../helper/awsRum";

export const LOGIN_URL = 'https://login.malsch.solutions/login?client_id=7i8vqs0cgjqikdcgo11eaktqh&response_type=token&scope=email+openid+profile&redirect_uri=https://login.vecodesk.com/login-callback'

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link target='_blank' color="inherit" href="https://malsch.solutions/">
                Dominik Malsch Solutions
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const email = (val) => EmailValidator.validate(val);
const getValidationSchema = (t) => {
    return new Schema({
        username: {
            required: true,
            type: String,
            use: {email},
            message: t('login.emailRequired'),
        },
        password: {
            required: true,
            type: String,
            message: t('login.passwordRequired'),
        }
    })
}

const getRenewValidationSchema = (t) => {
    return new Schema({
        newPassword: {
            required: true,
            type: String,
            message: t('login.passwordRequired'),
        },
        confirmPassword: {
            required: true,
            type: String,
            message: t('login.passwordRequired'),
        }
    })
}

const getForgotPasswordValidationSchema = (t) => {
    return new Schema({
        username: {
            required: true,
            type: String,
            use: {email},
            message: t('login.emailRequired'),
        }
    })
}
const getConfirmForgotPasswordValidationSchema = (t) => {
    return new Schema({
        code: {
            required: true,
            type: String,
            message: t('login.codeRequired'),
        },
        newPassword: {
            required: true,
            type: String,
            message: t('login.passwordRequired'),
        },
        confirmPassword: {
            required: true,
            type: String,
            message: t('login.passwordRequired'),
        }
    })
}


const get2FactorValidationSchema = (t) => {
    return new Schema({
        token: {
            required: true,
            type: String,
            message: t('login.tokenRequired'),
        }
    })
}


const Login = (props) => {
    const dispatch = useDispatch()
    const theme = useTheme();

    const [successMessage, setSuccessMessage] = useState(null)
    const [status, setStatus] = useState(null)
    const [loggingIn, setLoggingIn] = useState(false);
    const [session, setSession] = useState(null);
    const [renewingPassword, setRenewingPassword] = useState(false);
    const [validatingMfa, setValidatingMfa] = useState(false);
    const [errorList, setErrorList] = useState({});
    const [error, setError] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [maintenance, setMaintenance] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [forgotEmail, setForgotEmail] = useState('');
    const [forgotPasswordRequestPending, setForgotPasswordRequestPending] = useState(false);
    const [confirmForgotPasswordRequestPending, setConfirmForgotPasswordRequestPending] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState('email');
    const [forgotNewPassword, setForgotNewPassword] = useState('');
    const [confirmForgotNewPassword, setConfirmForgotNewPassword] = useState('');
    const [forgotCode, setForgotCode] = useState('');
    const [redirectPath, setRedirectPath] = useState('/');
    useEffect(() => {
        Api.fetch({
            endpoint: 'public/changelog/current',
            auth: false
        }).then((res) => {
            //todo
        }, (err) => {
            if (err.message === 'maintenance') {
                setMaintenance(true)
            } else {
                setError(err.message || "Internal Server Error")
            }
        })
    }, []);

    const history = useSelector(state => state.locationHistory);
    useEffect(() => {
        const entries = history.filter((entry) => entry.pathname !== '/login' && entry.pathname !== '/login-callback')
        if (entries.length && entries.length > 1) {
            const location = entries[1]
            setRedirectPath(location.pathname + location.search + location.hash)
        }
    }, [history])

    const {t} = useTranslation();
    const onRenew = (e) => {
        e.preventDefault();

        setRenewingPassword(true);
        setErrorList({})
        setError(null)
        const renewData = {
            username: username,
            newPassword: newPassword,
            session: session
        }

        const v = getRenewValidationSchema(t)

        const errors = v.validate(Object.assign({confirmPassword: confirmPassword}, renewData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setRenewingPassword(false);
            setErrorList(errorObject)
            return;
        }

        if (confirmPassword !== renewData.newPassword) {
            const errorObject = {
                confirmPassword: t('login.passwordMismatch')
            }
            setRenewingPassword(false);
            setErrorList(errorObject)
            return;
        }


        Api.fetch({
            endpoint: 'user/force-change-password',
            method: "POST",
            auth: false,
            body: renewData
        }).then((res) => {
            localStorage.setItem('user_name', username)
            localStorage.setItem('access_token', res.response.accessToken)
            localStorage.setItem('refresh_token', res.response.refreshToken)
            localStorage.setItem('id_token', res.response.idToken)
            dispatch(push(redirectPath))
        }, (err) => {
            setError(err.message || "Internal Server Error")
        }).then(() => {
            setRenewingPassword(false);
        });
    }

    const on2Factor = (e) => {
        e.preventDefault();

        setValidatingMfa(true);
        setErrorList({})
        setError(null)
        const twoFactorData = {
            username: username,
            token: token,
            session: session
        }

        const v = get2FactorValidationSchema(t)
        const errors = v.validate(Object.assign({}, twoFactorData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setValidatingMfa(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/validate-mfa',
            method: "POST",
            auth: false,
            body: twoFactorData
        }).then((res) => {
            localStorage.setItem('user_name', username)
            localStorage.setItem('access_token', res.response.accessToken)
            localStorage.setItem('refresh_token', res.response.refreshToken)
            localStorage.setItem('id_token', res.response.idToken)
            dispatch(push(redirectPath))
        }, (err) => {
            setError(err.message || "Internal Server Error")
        }).then(() => {
            setValidatingMfa(false);
        });
    }

    const onCancel = () => {
        setStatus(null);
        setError(null)
        setErrorList({})


    }

    const onForgotPassword = (e) => {
        e.preventDefault();
        setForgotPasswordRequestPending(true);
        setErrorList({})
        setError(null)

        const confirmData = {
            username: forgotEmail,
        }

        const v = getForgotPasswordValidationSchema(t)
        const errors = v.validate(cloneDeep(confirmData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setForgotPasswordRequestPending(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/forgot-password',
            method: "POST",
            auth: false,
            body: {
                username: forgotEmail
            }
        }).then((res) => {
            setError(null)
            setErrorList({})
            setDeliveryMethod(res.response.deliveryMedium)
            setStatus("FORGOT_PASSWORD_CONFIRM")
            setForgotCode('');
            setConfirmForgotNewPassword('');
            setForgotNewPassword('');
        }, (err) => {
            setError(err.message || "Internal Server Error")
        }).then(() => {
            setForgotPasswordRequestPending(false);
        });
    }

    const onConfirmForgotNewPassword = (e) => {
        e.preventDefault();
        setConfirmForgotPasswordRequestPending(true);
        setErrorList({})
        setError(null)

        const v = getConfirmForgotPasswordValidationSchema(t)

        const confirmData = {
            username: forgotEmail,
            newPassword: forgotNewPassword,
            code: forgotCode
        }

        const errors = v.validate(Object.assign({confirmPassword: confirmForgotNewPassword}, confirmData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setConfirmForgotPasswordRequestPending(false);
            setErrorList(errorObject)
            return;
        }

        if (confirmForgotNewPassword !== confirmData.newPassword) {
            const errorObject = {
                confirmPassword: t('login.passwordMismatch')
            }
            setConfirmForgotPasswordRequestPending(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/confirm-forgot-password',
            auth: false,
            method: "POST",
            body: {
                username: forgotEmail,
                newPassword: forgotNewPassword,
                code: forgotCode
            }
        }).then((res) => {
            setSuccessMessage(t('login.passwordChanged'))
            setError(null)
            setErrorList({})
            setStatus(null)
        }, (err) => {
            setError(err.message || "Internal Server Error")
        }).then(() => {
            setConfirmForgotPasswordRequestPending(false);
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        localStorage.removeItem('user_name')
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
        dispatch(clearUser());
        dispatch(clearSystemData());
        dispatch(clearUserNotifications());

        setLoggingIn(true);
        setErrorList({})
        setError(null)
        const loginData = {
            username: username,
            password: password
        }

        const v = getValidationSchema(t)

        const errors = v.validate(Object.assign({}, loginData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setLoggingIn(false);
            setErrorList(errorObject)
            return;
        }


        Api.fetch({
            endpoint: 'user/login',
            method: "POST",
            auth: false,
            body: loginData
        }).then((res) => {
            if (!res.response.challengeName) {
                setStatus(null)
                localStorage.setItem('user_name', username)
                localStorage.setItem('access_token', res.response.accessToken)
                localStorage.setItem('refresh_token', res.response.refreshToken)
                localStorage.setItem('id_token', res.response.idToken)
                dispatch(push(redirectPath))
            } else if (res.response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setStatus('NEW_PASSWORD_REQUIRED')
                setNewPassword('')
                setSession(res.response.session);
            } else if (res.response.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setStatus('SOFTWARE_TOKEN_MFA')
                setToken('')
                setSession(res.response.session);
            } else {
                setError("Invalid challenge")
            }
        }, (err) => {
            setError(err.message || "Internal Server Error")
        }).then(() => {
            setLoggingIn(false);
        });
    }

    const onClickSso = () => {
        window.location = LOGIN_URL;
    }

    const location = useLocation();
    useEffect(() => {
        AwsRumSingleton.getInstance()?.recordPageView(location.pathname);
    }, [location]);

    if(maintenance) {
        return <MaintenanceScreen />
    }

    return <Grid container component="main" sx={{height: '100vh'}}>
        <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
                backgroundImage: 'url(' + BackgroundImage + ')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                {status === null && <React.Fragment>
                    <Typography component="h1" variant="h5">
                        {t('login.signIn')}
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}} onSubmit={onSubmit}>
                        {!!successMessage &&
                            <Alert sx={{marginBottom: theme.spacing(2)}} severity='success'>{successMessage}</Alert>}
                        {!!error && <Alert sx={{marginBottom: theme.spacing(2)}} severity='error'>{error}</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={loggingIn}
                            id="email"
                            label={t('login.email')}
                            name="email"
                            autoComplete="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoFocus
                            error={errorList.hasOwnProperty('username')} helperText={errorList.username}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={loggingIn}
                            name="password"
                            label={t('login.password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={errorList.hasOwnProperty('password')} helperText={errorList.password}
                        />
                        <LoadingButton
                            onClick={onSubmit}
                            loadingPosition="start"
                            loading={loggingIn}
                            startIcon={<LoginOutlined/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 1}}
                        >
                            {t('login.signIn')}
                        </LoadingButton>
                        <Button
                            component={Link}
                            startIcon={<OpenInNew/>}
                            type="submit"
                            onClick={onClickSso}
                            fullWidth
                            variant="outlined"
                            sx={{mt: 1, mb: 2}}
                        >
                            {t('login.adminLogin')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link variant="body2" onClick={() => {
                                    setForgotEmail(username);
                                    setStatus('FORGOT_PASSWORD')
                                }}>
                                    {t('login.forgotPassword')}?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </React.Fragment>}
                {status === 'NEW_PASSWORD_REQUIRED' && <React.Fragment>
                    <Typography component="h1" variant="h5">
                        {t('login.changePassword')}
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}} onSubmit={onRenew}>
                        {!!error && <Alert sx={{marginBottom: theme.spacing(2)}} severity='error'>{error}</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={renewingPassword}
                            name="newPassword"
                            label={t('login.newPassword')}
                            type="password"
                            id="newPassword"
                            autoComplete="current-password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={errorList.hasOwnProperty('newPassword')} helperText={errorList.newPassword}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={renewingPassword}
                            name="confirmPassword"
                            label={t('login.confirmPassword')}
                            type="password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={errorList.hasOwnProperty('confirmPassword')} helperText={errorList.confirmPassword}
                        />
                        <LoadingButton
                            onClick={onRenew}
                            loadingPosition="start"
                            loading={renewingPassword}
                            startIcon={<LoginOutlined/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 1}}
                        >
                            {t('login.saveNewPassword')}
                        </LoadingButton>
                        <Button fullWidth variant='outlined' onClick={onCancel}>{t('cancel')}</Button>
                    </Box>
                </React.Fragment>}
                {status === 'SOFTWARE_TOKEN_MFA' && <React.Fragment>
                    <Typography component="h1" variant="h5">
                        {t('login.2factor')}
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}} onSubmit={on2Factor}>
                        {!!error && <Alert sx={{marginBottom: theme.spacing(2)}} severity='error'>{error}</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={validatingMfa}
                            name="token"
                            label={t('login.token')}
                            type="text"
                            id="token"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                            error={errorList.hasOwnProperty('token')} helperText={errorList.token}
                        />
                        <LoadingButton
                            onClick={on2Factor}
                            loadingPosition="start"
                            loading={validatingMfa}
                            startIcon={<LoginOutlined/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 1}}
                        >
                            {t('login.signIn')}
                        </LoadingButton>
                        <Button fullWidth variant='outlined' onClick={onCancel}>{t('cancel')}</Button>
                    </Box>
                </React.Fragment>}
                {status === 'FORGOT_PASSWORD' && <React.Fragment>
                    <Typography component="h1" variant="h5">
                        {t('login.forgotPassword')}
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}} onSubmit={onForgotPassword}>
                        {!!error && <Alert sx={{marginBottom: theme.spacing(2)}} severity='error'>{error}</Alert>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={forgotPasswordRequestPending}
                            name="email"
                            label={t('login.email')}
                            type="text"
                            id="email"
                            value={forgotEmail}
                            onChange={(e) => setForgotEmail(e.target.value)}
                            error={errorList.hasOwnProperty('username')} helperText={errorList.username}
                        />
                        <LoadingButton
                            onClick={onForgotPassword}
                            loadingPosition="start"
                            loading={forgotPasswordRequestPending}
                            startIcon={<Lock/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 1}}
                        >
                            {t('login.forgotPassword')}
                        </LoadingButton>
                        <Button fullWidth variant='outlined' onClick={onCancel}>{t('cancel')}</Button>
                    </Box>
                </React.Fragment>}
                {status === 'FORGOT_PASSWORD_CONFIRM' && <React.Fragment>
                    <Typography component="h1" variant="h5">
                        {t('login.changePassword')}
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 1}} onSubmit={onConfirmForgotNewPassword}>
                        {!!error && <Alert sx={{marginBottom: theme.spacing(2)}} severity='error'>{error}</Alert>}
                        <Alert sx={{marginBottom: theme.spacing(2)}}
                               severity='info'>{deliveryMethod === 'email' ? t('login.codeSentEmail') : t('login.codeSentSms')}</Alert>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={validatingMfa}
                            label={t('login.code')}
                            type="text"
                            value={forgotCode}
                            onChange={(e) => setForgotCode(e.target.value)}
                            error={errorList.hasOwnProperty('code')} helperText={errorList.code}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={confirmForgotPasswordRequestPending}
                            label={t('login.newPassword')}
                            type="password"
                            autoComplete="current-password"
                            value={forgotNewPassword}
                            onChange={(e) => setForgotNewPassword(e.target.value)}
                            error={errorList.hasOwnProperty('newPassword')} helperText={errorList.newPassword}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            disabled={confirmForgotPasswordRequestPending}
                            label={t('login.confirmPassword')}
                            type="password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={confirmForgotNewPassword}
                            onChange={(e) => setConfirmForgotNewPassword(e.target.value)}
                            error={errorList.hasOwnProperty('confirmPassword')} helperText={errorList.confirmPassword}
                        />
                        <LoadingButton
                            onClick={onConfirmForgotNewPassword}
                            loadingPosition="start"
                            loading={confirmForgotPasswordRequestPending}
                            startIcon={<LoginOutlined/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 1}}
                        >
                            {t('login.saveNewPassword')}
                        </LoadingButton>
                        <Button fullWidth variant='outlined' onClick={onCancel}>{t('cancel')}</Button>
                    </Box>
                </React.Fragment>}
                <Copyright sx={{mt: 5}}/>
            </Box>
        </Grid>
    </Grid>
}

export default Login;
