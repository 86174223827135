import {GROUP_LIST_FAILURE, GROUP_LIST_REQUEST, GROUP_LIST_SUCCESS} from "../actions/groupActions";

export function groupsReducer(state = {
    isFetching: false,
    error: null,
    list: []
}, action) {
    switch (action.type) {
        case GROUP_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case GROUP_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case GROUP_LIST_SUCCESS:
            const list = action.response;
          
            list.sort((a, b) => {
                if (a.position !== b.position) {
                    return a.position - b.position;
                }
                return a.name.localeCompare(b.name);
            })

            return Object.assign({}, state, {
                isFetching: false,
                list: list
            });
        default:
            return state
    }
}

