import {combineReducers} from 'redux'
import {createRouterReducer} from '@lagunovsky/redux-react-router'
import alertReducer from "../reducer/alertReducer";
import {requestCountReducer} from "../reducer/requestCountReducer";
import {userReducer} from "../reducer/userReducer";
import createLocationChangeReducer from "../reducer/locationChangeReducer";
import {groupsReducer} from "../reducer/groupsReducer";
import {userListReducer} from "../reducer/userListReducer";
import {categoriesReducer} from "../reducer/categoriesReducer";
import {statusReducer} from "../reducer/statusReducer";
import {tagReducer} from "../reducer/tagReducer";
import {systemDataReducer} from "../reducer/systemDataReducer";
import {propertyReducer} from "../reducer/propertyReducer";
import {customerMapReducer} from "../reducer/customerMapReducer";
import {deviceTypeReducer} from "../reducer/deviceTypeReducer";
import {numberRangeReducer} from "../reducer/numberRangeReducer";
import {taskReducer} from "../reducer/taskReducer";
import {requestReducer} from "../reducer/requestReducer";
import {notificationReducer} from "../reducer/notificationReducer";
import {userNotificationsReducer} from "../reducer/userNotificationsReducer";
import {connectionsReducer} from "../reducer/connectionsReducer";
import {toursReducer} from "../reducer/toursReducer";
import {latestNotificationReducer} from "../reducer/latestNotificationReducer";

const createRootReducer = (history) => combineReducers({
    router: createRouterReducer(history),
    alerts: alertReducer,
    requestCount: requestCountReducer,
    user: userReducer,
    userList: userListReducer,
    categories: categoriesReducer,
    tasks: taskReducer,
    tags: tagReducer,
    statusList: statusReducer,
    locationHistory: createLocationChangeReducer(history),
    groups: groupsReducer,
    notifications: notificationReducer,
    latestNotification: latestNotificationReducer,
    notificationSettings: userNotificationsReducer,
    requests: requestReducer,
    systemData: systemDataReducer,
    properties: propertyReducer,
    mapSettings: customerMapReducer,
    deviceTypes: deviceTypeReducer,
    numberRange: numberRangeReducer,
    connections: connectionsReducer,
    tours: toursReducer
});

export default createRootReducer
