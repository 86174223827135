import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../../../core/Api";
import {Add, Replay, Save} from "@mui/icons-material";
import {useTheme} from "@mui/system";
import Row from "./Row";
import {LoadingButton} from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";
import {arrayMoveImmutable} from "array-move";
import {Container} from "react-smooth-dnd";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [groups, setGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchGroups()
    }, []);

    const fetchGroups = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'groups'
        })
            .then((res) => {
                const groups = res.response;
                groups.sort((a, b) => {
                    if (a.position !== b.position) {
                        return a.position - b.position;
                    }
                    return a.name.localeCompare(b.name);
                })
                setGroups(groups);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        groups.push({id: uuid(), name: '', description: '', isNew: true, position: groups.length})
        setGroups(groups.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (groups.some(group => group.hasErrors && !group.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = groups.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((g) => {
            const group = Object.assign({}, g);
            const isNew = group.isNew;
            const isDeleted = group.isDeleted;
            const hasChanges = group.hasChanges;

            delete group.isNew;
            delete group.isDeleted;
            delete group.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'groups', method: 'POST', body: group})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'groups/' + group.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'groups/' + group.id, method: 'PUT', body: group})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchGroups();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = groups.some((group) => group.hasChanges || group.isNew || group.isDeleted);

    return <React.Fragment>
        {hasError &&
            <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.groups.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['groups.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd}
                        startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.groups.addGroup')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchGroups}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.groups.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.groups.description')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <Container lockAxis="y" dragHandleSelector=".drag-handle"
                           onDrop={({removedIndex, addedIndex}) => {
                               console.log(removedIndex, addedIndex)
                               setGroups((oldGroups => {
                                   return arrayMoveImmutable(oldGroups, removedIndex, addedIndex).map((value, index) => {
                                       if (value.position !== index) {
                                           value.hasChanges = true;
                                           value.position = index;
                                       }
                                       return value
                                   });
                               }));
                           }}
                           render={(ref) => (
                               <TableBody ref={ref}>
                                   {groups.filter((g) => !g.isDeleted).map((group, i) => (
                                       <Row key={i} group={group}
                                            onDelete={() => {
                                                setGroups((groups) => {
                                                    const i = groups.findIndex((c) => c.id === group.id);
                                                    const deleteGroup = groups[i];

                                                    if (deleteGroup.isNew) {
                                                        groups.splice(i, 1)
                                                    } else {
                                                        deleteGroup.isDeleted = true;
                                                        groups[i] = deleteGroup;
                                                    }
                                                    return groups.slice();
                                                })
                                            }}
                                            onChange={(changedGroup) => {
                                                setGroups((groups) => {
                                                    const i = groups.findIndex((c) => c.id === changedGroup.id);
                                                    groups[i] = changedGroup;
                                                    return groups.slice();
                                                })
                                            }}
                                       />
                                   ))}
                               </TableBody>
                           )}
                />
            </Table>
            {groups.filter((g) => !g.isDeleted).length === 0 &&
                <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                    <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                        <Typography variant='body2'>{t('settings.groups.noGroupsCreated')}</Typography>
                        <Link variant='body2' onClick={onAdd}>{t('settings.groups.addGroup')}</Link>
                    </Box>
                </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
