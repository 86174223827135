import {ROUTER_ON_LOCATION_CHANGED} from '@lagunovsky/redux-react-router';

export default function createLocationChangeReducer(history) {
    return function locationChangeReducer(state = [history.location], action) {
        switch (action.type) {
            case ROUTER_ON_LOCATION_CHANGED:
                console.log('locationChangeReducer', action.payload)
                switch (action.payload.action) {
                    case 'PUSH':
                        return [action.payload.location, ...state].slice(0, 20)
                    case 'REPLACE':
                        return [action.payload.location, ...state.slice(1)].slice(0, 20)
                }
                return state;
            default:
                return state;
        }
    }
}