import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import {ChevronLeft, ViewList} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Divider, Stack} from "@mui/material";

const BackButton = ({defaultTo, exclude}) => {
    const {t} = useTranslation();
    const [to, setTo] = React.useState(defaultTo);
    const history = useSelector(state => state.locationHistory);


    useEffect(() => {
        console.log(history)
        const entries = history.filter((entry, i) => {
            if (i === 0) {
                return false
            }
            if (entry.pathname === '/login' || entry.pathname === '/login-callback') {
                return false
            }
            if (exclude && Array.isArray(exclude)) {
                for (const excludePath of exclude) {
                    if (entry.pathname.startsWith(excludePath)) {
                        console.log('exclude', excludePath);
                        return false
                    }
                }
            }
            return true
        })
        console.log('entries', entries)
        if (entries.length && entries.length > 0) {
            const location = entries[0]
            setTo(location.pathname + location.search + location.hash)
        }
    }, [history])

    return (<Stack direction="row" alignItems="center" gap={1}>
        <Button component={RouterLink} to={to}
                startIcon={<ChevronLeft/>}
                color='primary'>{t('back')}</Button>
        <Divider orientation="vertical" flexItem variant='middle' />
        <Button component={RouterLink} to={defaultTo}
                startIcon={<ViewList/>}
                color='primary'>{t('backToList')}</Button>
    </Stack>);
};

export default BackButton;