import {IconButton, TableCell, TableRow, TextField} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Schema from "validate";
import DeleteDialog from "../../../Utils/DeleteDialog";
import {Delete, DragHandle} from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";
import {Draggable} from "react-smooth-dnd";

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String
        },
    })
}

const Row = ({group, onChange, onDelete}) => {
    const {t} = useTranslation();
    const [errorList, setErrorList] = React.useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onDeleteClick = () => {
        setDeleteOpen(false);
        onDelete();
    }

    useEffect(() => {
        const v = getValidationSchema(t);
        const errors = v.validate({name: group.name})
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setErrorList(errorObject)
            group.hasErrors = true;
            onChange(group)
        } else {
            group.hasErrors = false;
            setErrorList({})
            onChange(group)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group.name, t]);

    return <React.Fragment>
        <Draggable key={group.id ?? 0} render={(ref) => (
            <TableRow ref={ref} style={{display: 'table-row'}} >
                <TableCell width={50}>
                    <IconButton><DragHandle className='drag-handle'/></IconButton>
                </TableCell>
                <TableCell width={300}>
                    <TextField
                        fullWidth
                        variant='standard'
                        placeholder={t('settings.groups.name')}
                        value={group.name}
                        onChange={(e) => {
                            group.name = e.target.value
                            group.hasChanges = true
                            onChange(group)
                        }}
                        error={errorList.hasOwnProperty('name')}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        variant='standard'
                        placeholder={t('settings.groups.description')}
                        value={group.description}
                        onChange={(e) => {
                            group.description = e.target.value
                            group.hasChanges = true
                            onChange(group)
                        }}
                    />
                </TableCell>
                <TableCell width={50}>
                    <CheckPermissions list={['groups.delete']}>
                        <IconButton onClick={() => setDeleteOpen(true)} align="right"><Delete/></IconButton>
                    </CheckPermissions>
                </TableCell>
            </TableRow>
        )} />
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('settings.groups.deleteGroup')}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('settings.groups.deleteGroupDescription', {name: group.name || ''})}
                      open={deleteOpen}/>
    </React.Fragment>
}

export default Row
